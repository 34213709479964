/**
 * JQUERY READY
 */
jQuery( document ).ready(function() {
  console.log('FRONT-END "WEB PUBLICA" AUX FUNCTIONS READY');
});

/**
 * SHOW FORM RESULT MESSAGE
 * @param {object} parentDomElem 
 * @param {string} msgDomElem 
 * @param {string} display 
 * @param {boolean} resetForm 
 * @param {string} addClass 
 * @param {string} removeClass 
 * @param {string} message 
 */
export function showResultMessage(parentDomElem, msgDomElem, message, resetForm = false, addClass, display = 'block', removeClass = ''){
  //console.log('showResultMessage FUNCTION');
  parentDomElem.find(msgDomElem).css('display', display);
  if(resetForm){
    parentDomElem.trigger('reset');
  }

  if(removeClass){
    parentDomElem.find(msgDomElem + ' p').removeClass(removeClass);
  }
  parentDomElem.find(msgDomElem + ' p').addClass(addClass).html(message);
}

/**
 * HIDE FORM RESULT MESSAGE
 * @param {object} parentDomElem 
 * @param {string} msgDomElem 
 * @param {int} timeOffset 
 */
export function hideResultMessage(parentDomElem, msgDomElem, timeOffset){
  //console.log('hideResultMessage FUNCTION');
  setTimeout(function(){
    parentDomElem.find(msgDomElem).css('display', 'none');
    //parentDomElem.find(msgDomElem).fadeOut(800);
    parentDomElem.find(msgDomElem + ' p').removeClass('success').removeClass('error').html('');
  }, timeOffset);
}

export function resetSubmitBtn(parentDomElem, originalSubmitText){
  //console.log('resetSubmitBtn FUNCTION');
  parentDomElem.find('button[type=submit]').removeClass('disabled sending');
  parentDomElem.find('button[type=submit] span').html(originalSubmitText);
  parentDomElem.find('button[type=submit]').prop('disabled', false);
}

export function changeSubmitBtnStatus(parentDomElem, text = 'Enviando...'){
  //console.log('changeSubmitBtnStatus FUNCTION');
  parentDomElem.find('button[type=submit]').addClass('disabled sending');
  parentDomElem.find('button[type=submit] span').html(text);
  parentDomElem.find('button[type=submit]').prop('disabled', true);
}
